import React, { useContext } from 'react';
import { graphql } from 'gatsby';

import { Hero } from '../components/ThankYou/Hero';
import { ThankYouBody } from '../components/ThankYou/ThankYouBody';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { SEO } from '../components/SEO';
import { ThumbnailFeatureModal } from '../components/General/ThumbnailFeatureModal';
import FeatureThumbnailContext from '../context/FeatureThumbnailContext';

export default function ThankYou({ data: { thankyou } }) {
	const { metaTitle, metaDescription, hero } = thankyou;

	const { modalOpen, setModalOpen, selectedThumbnail } = useContext(
		FeatureThumbnailContext
	);

	const pageContent =
		thankyou.featureHeader || thankyou.featuredResources.length;
	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			<Hero hero={hero} pageContent={!!pageContent} />
			{!!pageContent ? <ThankYouBody thankyou={thankyou} /> : null}
			<div>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
			<ThumbnailFeatureModal
				open={modalOpen}
				setFeatureModalOpen={setModalOpen}
				wistiaLink={selectedThumbnail}
				feature
			/>
		</>
	);
}

export const query = graphql`
	query ($slug: String!) {
		thankyou: sanityThankYou(slug: { current: { eq: $slug } }) {
			_id
			title
			metaTitle
			metaDescription
			slug {
				current
			}
			hero {
				ctaText
				ctaLink
				_rawContent
				backgroundImage {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
			}
			featureHeader
			_rawFeatureBody
			videoVariant
			featureImage {
				asset {
					gatsbyImageData(placeholder: BLURRED)
				}
			}
			# svgVariant
			thumbnailVideoUrl
			fileDownload {
				asset {
					url
				}
			}
			downloadCTAText
			shareText
			socialAssets {
				faIcon
				socialLink
				title
			}
			featuredResourcesHeader
			featuredResources {
				title
				blurb
				ctaText
				ctaLink
				image {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
			}
			customerLogosHeader
			customerLogos {
				logo {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
			}
			circleImageLeft {
				asset {
					gatsbyImageData(placeholder: BLURRED)
				}
			}
			circleImageRight {
				asset {
					gatsbyImageData(placeholder: BLURRED)
				}
			}
		}
	}
`;
